<template>
	<div>
		<div>
			<Blocks :blocks="blocks" />
		</div>
		<div v-if="$route.fullPath !== '/work-together'">
			<WorkTogether />
		</div>
	</div>
</template>

<script>
import sanity from "../client";
import Blocks from "../components/new/Blocks";
import WorkTogether from "../components/WorkTogether.vue";

const query = `*[_type == "page" && slug.current == $slug][0]{
    _id,
    title,
    slug,
    blocks[] {
        ...,
				link {
					pages->{
						slug
					},
					externalLink
				}
    }
}`;

export default {
	name: "Page",
	components: {
		Blocks,
		WorkTogether
	},
	data() {
		return {
			loading: true,
			pages: [],
			title: "",
			blocks: [],
			error: ""
		};
	},
	created() {
		this.fetchPage();
	},
	methods: {
		fetchPage() {
			this.error = this.pages = null;
			this.loading = true;
			sanity.fetch(query, { slug: this.$route.params.slug }).then(
				(pages) => {
					this.loading = false;
					this.pages = pages;
					this.blocks = pages.blocks;
					this.title = pages.title;
				},
				(err) => {
					console.error(err);
				}
			);
		}
	}
};
</script>
